import {
  Box,
  LinearProgress,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useMemo } from "react";
import cx from "classnames";
import Button from "../Button";

import { useCommonStyles } from "../../../../utils/loan-application.styles";
import { borderColor, primaryColor } from "../../../../scss/colors.scss";
import { UploadIcon } from "../../../../svgs/loan-application";
import { useNotification } from "../../../../hooks/loan-application.hooks";

const useStyles = makeStyles({
  cardContainer: {
    padding: "12px",
    border: `2px solid ${borderColor}`,
    borderRadius: "3px",
    minHeight: "64px",
    minWidth: "256px",
    width: "100%",
  },
  uploadButton: {
    padding: "0px 16px",
    height: "40px",
  },
  dropZone: {
    border: `2px dashed ${borderColor}`,
    padding: "16px",
    borderRadius: "3px",
  },
  trackColor: {
    backgroundColor: borderColor,
  },
  sliderColor: {
    backgroundColor: primaryColor,
  },
  actionButton: {
    padding: "0",
  },
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    cursor: "pointer",
    paddingRight: "24px",
  },
  fileList: {
    maxHeight: "200px",
    overflowY: "auto",
  },
  fileItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 0",
    borderBottom: `1px solid ${borderColor}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
});

const FILE_TYPE_TO_EXTENSION = {
  "image/png": "png",
  "image/jpeg": "jpg",
  "image/jpg": "jpg",
  "application/pdf": "pdf",
  "application/msword": "doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "docx",
};

const UploadCard = ({
  title,
  onUpload = async () => {},
  files = [],
  onDelete = async () => {},
  maxFiles = 5, // Default max files limit
  extension = ["png", "jpg", "jpeg", "pdf", "doc", "docx"],
  fileSizeLimit = 10, // MB
  onDownload = async () => {},
}) => {
  const { showNotification } = useNotification();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [progress, setProgress] = React.useState(0);
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [isUploading, setIsUploading] = React.useState(false);

  React.useEffect(() => {
    let timer;
    let interval;
    if (isUploading) {
      // Reset progress when starting upload
      setProgress(0);
      interval = setInterval(() => {
        setProgress((oldProgress) => {
          // While uploading, progress goes up to 90%
          if (oldProgress >= 90) {
            return 90;
          }
          return Math.min(oldProgress + 5, 90);
        });
      }, 100);
    } else if (progress > 0) {
      // When upload finishes, quickly reach 100%
      timer = setTimeout(() => {
        setProgress(100);
        // Optional: Reset progress after a brief delay
        setTimeout(() => setProgress(0), 500);
      }, 100);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isUploading]);

  const isUploadDisabled = useMemo(
    () => files.length >= maxFiles || selectedFiles.length === 0,
    [files, maxFiles, selectedFiles]
  );

  const fileList = useMemo(() => {
    return files.concat(selectedFiles);
  }, [files, selectedFiles]);

  const isSelectFileDisabled = useMemo(() => fileList.length >= maxFiles, [
    fileList,
    maxFiles,
  ]);

  const handleSelectFile = useCallback(
    (file) => {
      const fileExtension = FILE_TYPE_TO_EXTENSION[file.type];
      if (!fileExtension || !extension.includes(fileExtension)) {
        showNotification(`Invalid file type`, "error");
        return;
      }
      if (file && file.size > fileSizeLimit * 1024 * 1024) {
        showNotification(
          `File size must be less or equal to ${fileSizeLimit}MB`,
          "error"
        );
        return;
      }
      // isNew means that file is not uploaded yet
      // eslint-disable-next-line no-param-reassign
      file.isNew = true;
      setSelectedFiles([...selectedFiles, file]);
    },
    [showNotification, fileSizeLimit, selectedFiles, extension]
  );

  const extensionAccept = useMemo(
    () => extension.map((item) => `.${item}`.toLowerCase()).join(","),
    [extension]
  );

  const onSelectFile = useCallback(() => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = extensionAccept;
    input.style.display = "none";

    input.onchange = (e) => {
      const file = e.target.files?.[0];
      handleSelectFile(file);
    };

    input.click();
    input.remove();
  }, [extensionAccept, handleSelectFile]);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      const file = e.dataTransfer.files?.[0];
      handleSelectFile(file);
    },
    [handleSelectFile]
  );

  const handleUpload = useCallback(async () => {
    setIsUploading(true);
    await onUpload(selectedFiles)
      .then(() => {
        setSelectedFiles([]);
      })
      .finally(() => {
        setIsUploading(false);
      });
  }, [onUpload, selectedFiles]);

  const handleDownload = useCallback(
    async (file) => {
      await onDownload(file);
    },
    [onDownload]
  );

  const handleDelete = useCallback(
    async (file) => {
      if (file.isNew) {
        setSelectedFiles(selectedFiles.filter((f) => f !== file));
      } else {
        await onDelete(file);
      }
    },
    [onDelete, selectedFiles]
  );

  return (
    <Box
      classes={{ root: classes.cardContainer }}
      display="flex"
      flexDirection="column"
      gridRowGap="16px"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gridColumnGap="32px"
      >
        <Box
          display="flex"
          flexDirection="column"
          gridRowGap="8px"
          flexGrow={1}
        >
          <Typography classes={{ root: commonClasses.fontSize16 }}>
            {title}
            <span>
              {fileList?.length > 0 && (
                <Typography
                  variant="text"
                  classes={{ root: commonClasses.fontSize12 }}
                >
                  &nbsp;&nbsp;(max: {fileList.length} / {maxFiles})
                </Typography>
              )}
            </span>
          </Typography>
          {isUploading && (
            <LinearProgress
              variant="determinate"
              value={progress}
              classes={{
                colorPrimary: classes.trackColor,
                barColorPrimary: classes.sliderColor,
              }}
            />
          )}
        </Box>
        <Button
          onClick={() => handleUpload()}
          classes={classes.uploadButton}
          disabled={isUploadDisabled}
          loading={isUploading}
        >
          Upload
        </Button>
      </Box>
      {!isSelectFileDisabled && !isUploading && (
        <Box
          display="flex"
          flexDirection="column"
          gridRowGap="16px"
          alignItems="center"
          classes={{ root: classes.dropZone }}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <UploadIcon width={40} height={40} />
          <Typography
            classes={{
              root: cx(commonClasses.fontSize14, commonClasses.textCenter),
            }}
          >
            Drop files here or{" "}
            <Typography variant="text" style={{ textDecoration: "underline" }}>
              <span onClick={onSelectFile} style={{ cursor: "pointer" }}>
                browse files
              </span>
            </Typography>{" "}
            from your computer
          </Typography>
        </Box>
      )}
      {fileList.length > 0 && (
        <Box classes={{ root: classes.fileList }}>
          {fileList.map((file, index) => (
            <Box
              // eslint-disable-next-line react/no-array-index-key
              key={`${file.name}-${index}`}
              classes={{ root: classes.fileItem }}
            >
              <Tooltip title={file.name}>
                <Typography noWrap className={classes.ellipsis}>
                  {file.name}
                </Typography>
              </Tooltip>
              <Box display="flex">
                {!file.isNew && (
                  <Button
                    onClick={() => handleDownload(file)}
                    variant="text"
                    classes={classes.actionButton}
                  >
                    Download
                  </Button>
                )}
                <Button
                  onClick={() => handleDelete(file)}
                  variant="text"
                  classes={classes.actionButton}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default UploadCard;
